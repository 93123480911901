<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      token: this.$route.query.token,
      status: this.$route.query.status,
      menu: "",
      username: "",
      password: "",
      is_one_time: false,
      configuration: [],
      source: "",
      role_upac: this.$route.query.role,
    };
  },
  mounted() {
    localStorage.removeItem("user");
    localStorage.removeItem("session");
    localStorage.removeItem("session_username");
    localStorage.removeItem("session_name");
    localStorage.removeItem("session_path_photo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("session_role");
    localStorage.removeItem("session_role_active_id");
    localStorage.removeItem("session_role_active_name");
    localStorage.removeItem("session_role_active_index");
    localStorage.removeItem("session_menu_access");
    localStorage.removeItem("reload_already");
    localStorage.removeItem("dateLogin");
    localStorage.removeItem("status");
    localStorage.removeItem("menu");
    localStorage.removeItem("role_upac");
    this.configLogin();
  },
  methods: {
    async prosesJumper() {
      let self = this;
      let status_fix = "";
      if (self.status == undefined) {
        status_fix = "all";
      } else {
        status_fix = self.status;
      }
      var config_axios = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/loginportal",
        data: {
          token: this.token,
          status: status_fix,
          source: this.source,
          role: this.role_upac,
        },
      };
      axios(config_axios)
        .then((response) => {
          if (response.data.meta.code == 200) {
            this.username = response.data.data.username;
            this.password = this.username;
            this.source = response.data.data.source;
            this.role_upac = response.data.data.role_upac;
            this.status = response.data.data.status;
            this.menu = response.data.data.menu;
            this.prosesLogin(this.username, this.status, this.menu);
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Token anda tidak valid, Silahkan login ulang",
          }).then(function () {
            let a = document.createElement("a");
            a.href = process.env.VUE_APP_UPAC_CI_URL + "webadmin/login";
            a.click();
          });
        });
    },
    prosesLogin(username, status, menu) {
      let self = this;
      axios
        .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
          username: username,
          password: username,
          is_one_time: this.is_one_time,
        })
        .then((response) => {
          var data_login = response.data.data;
          // check status login
          if (data_login.status_login == false) {
            Swal.fire({
              icon: "warning",
              title: "Oopss",
              text: data_login.message_login,
              showCancelButton: false,
              showConfirmButton: true,
            });
          } else {
            // inisialisasi variabel localstorage untuk session
            localStorage.setItem("session_user_id", response.data.data.user.master_user_id); // session user id

            localStorage.setItem("session_role_upac", this.role_upac); // session user role dinamis

            localStorage.setItem("session_source", this.source); // session user role dinamis

            localStorage.setItem("session_username", response.data.data.user.username); // session username

            localStorage.setItem("session_name", response.data.data.user.name); // session name

            localStorage.setItem("session_path_photo", response.data.data.user.path_photo); // session path photo

            localStorage.setItem("access_token", response.data.data.access_token); // akses token

            localStorage.setItem("session_role", JSON.stringify(response.data.data.role)); // session role

            localStorage.setItem("session_role_active_id", response.data.data.role[0].role.id_role); // session role active id

            localStorage.setItem("session_role_active_name", response.data.data.role[0].role.role_name); // session role active name

            localStorage.setItem("session_role_active_index", 0); // session role active index

            localStorage.setItem("session_menu_access", JSON.stringify(response.data.data.menu_access[0].role_menu)); // set session meu access user

            localStorage.setItem("session", JSON.stringify(response.data.data)); // set all response to session

            localStorage.setItem("user", JSON.stringify(response.data.data.user)); // set session user

            localStorage.setItem("configuration", JSON.stringify(self.configuration)); // set session configuration

            localStorage.setItem("reload_already", "no");
            localStorage.setItem("dateLogin", Date.now());

            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman dashboard segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */

              // return false;
              if (localStorage.session && localStorage.session_name && localStorage.access_token) {
                if (result.dismiss === Swal.DismissReason.timer) {
                  if (this.source == "CATALYST") {
                    this.$router.push({ name: "all-penilaian-pegpog" });
                  } else {
                    if (!menu) {
                      localStorage.setItem("status", status);
                      this.$router.push({
                        name: "dashboard",
                        params: { status: self.status },
                      });
                    }
                    if (menu == "Asesmen Center") {
                      localStorage.setItem("status", status);
                      this.$router.push({
                        name: "all-asesmen-center",
                        params: { status: self.status },
                      });
                    }
                    if (menu == "Asesmen Psikologi") {
                      localStorage.setItem("status", status);
                      this.$router.push({
                        name: "all-asesmen-psikologi",
                        params: { status: self.status },
                      });
                    }
                    if (menu == "Rekrutmen") {
                      localStorage.setItem("status", status);
                      this.$router.push({
                        name: "all-rekrutmen",
                        params: { status: self.status },
                      });
                    }
                    if (menu == "Rekap QC") {
                      localStorage.setItem("status", status);
                      this.$router.push({
                        name: "rekap-qc",
                        params: { status: self.status },
                      });
                    }
                  }
                }
              }
            });
          }
        })
        .catch((error) => {
          var message = error.response.data.data.error;
          if (message == "Session has been used") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
            });
          } else if (message == "Account cant find") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Periksa Kembali Username & Password Anda!",
            });
          } else if (message == "Something went wrong") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Terjadi Kesalahan, Coba lagi nanti",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Token anda tidak valid, Silahkan login ulang",
            }).then(function () {
              let a = document.createElement("a");
              a.href = process.env.VUE_APP_UPAC_CI_URL + "webadmin/login";
              a.click();
            });
          }
        });
    },
    configLogin() {
      let self = this;
      var config_login = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/konfig-login",
        headers: {
          Accept: "application/json",
        },
      };
      axios(config_login)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.configuration = response_data_fix.referensi;
            console.log(self.configuration);
            self.prosesJumper();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <!-- <h2 class="text-uppercase">Loading...</h2> -->

            <div class="mt-5 text-center"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
