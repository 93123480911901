<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";
import { encryptData } from "@/helpers/crypt.js";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penjadwalan Rekrutmen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_jadwal" },
      { width: "auto", label: "ID Jadwal", name: "id_jadwal" },
      { width: "auto", label: "Aksi", name: "-" },
      { width: "auto", label: "Grup", name: "Grup" },
      { width: "auto", label: "Tanggal Mulai", name: "j_tanggal_mulai" },
      { width: "auto", label: "Tanggal Selesai", name: "j_tanggal_sampai" },
      { width: "auto", label: "Kegiatan", name: "j_txt_kegiatan" },
      { width: "auto", label: "Undang", name: "ju_peserta_diundang" },
      { width: "auto", label: "Hadir", name: "j_peserta_hadir" },
      { width: "150px", label: "Keterangan", name: "j_keterangan" },
      { width: "auto", label: "Lokasi", name: "j_txt_lokasi" },
      { width: "auto", label: "PTL", name: "j_txt_ptl" },
      { width: "auto", label: "QC PTL", name: "j_txt_ptl" },
      { width: "auto", label: "Petugas", name: "j_json_petugas" },
      { width: "auto", label: "Kelengkapan Data", name: "id_jadwal" },
      { width: "auto", label: "Status", name: "j_status" },
      { width: "auto", label: "S. Undangan", name: "id_jadwal" },
      { width: "auto", label: "S. Penugasan EE", name: "id_jadwal" },
      { width: "auto", label: "Berita Acara", name: "id_jadwal" },
      // Laporan Rekap
      { width: "auto", label: "Target", name: "id_jadwal" },
      { width: "auto", label: "Realisasi", name: "id_jadwal" },
      { width: "auto", label: "No. Surat", name: "id_jadwal" },
      { width: "auto", label: "File", name: "id_jadwal" },
      // Laporan Lengkap
      { width: "auto", label: "Target", name: "id_jadwal" },
      { width: "auto", label: "Realisasi", name: "id_jadwal" },
      { width: "auto", label: "No. Surat", name: "id_jadwal" },
      { width: "auto", label: "File", name: "id_jadwal" },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Rekrutmen",
      items: [
        {
          text: "Rekrutmen",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      loadingExport: false,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      listYears: null,
      listMonths: null,
      listStatus: ["Draft", "verifikasi", "proses_ptl", "void", "Selesai"],
      listDocument: ["Lengkap", "Belum Lengkap"],
      showModalUpdateTarget: false,
      selectedIdJadwal: null,
      selectedSlug: null,
      update_file: null,
      update_tanggal_realisasi: null,
      update_no_surat: null,
      update_keterangan: null,
      file_temp: null,
      // option_jenis_asesmen: [],

      selected_jenis_asesmen: [],

      option_sub_kegiatan: [],
      selected_sub_kegiatan: [],

      // parsing data ke table data

      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_nip: JSON.parse(localStorage.getItem("session")).user.username,
        selectedYear: new Date().getFullYear(),
        selectedMonth: 0,
        selectedStatus: 0,
        selectedDocument: 0,
        id_sub_kegiatan: "",
        // filter_jenis_asesmen: "",
        // filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.last10Years();
    self.cekAksesMenu();
    self.getDataTable();
    self.allMonths();
    self.getSubKegiatan();
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    cekAksesMenu() {
      let self = this;
      var config_axios = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
        params: {
          role_id: self.sessionRoleId,
          link_name: self.namePathUrl,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_axios)
        .then((response) => {
          let response_data_fix = response.data.data.status_access;
          if (response.data.meta.code == 200) {
            if (response_data_fix == false) {
              Swal.fire({
                icon: "warning",
                title: "Oppss",
                text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "dashboard" });
                }
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/rekrutmen") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;
      // self.tableData.filter_jenis_asesmen = self.selected_jenis_asesmen.nama_jenisasesmen;
      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              console.log(self.table_data);
              self.configPagination(response_data_fix.list_data);
              self.currentTablePage = response_data_fix.list_data.current_page;
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    updateRekap(id, text) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin update rekap?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/update-rekap-penawaran",
            params: {
              idjadwal: this.selectedIdJadwal,
              slug: text,
            },
            data: {
              tanggal: this.update_tanggal_realisasi,
              no_surat: this.update_no_surat,
              keterangan: this.update_keterangan,
              file: self.file_temp,
              userid: localStorage.getItem("session_user_id"),
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              this.showModalUpdateTarget = false;
              (this.update_tanggal_realisasi = null),
                (this.update_no_surat = null),
                (this.update_keterangan = null),
                (self.file_temp = null),
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Data berhasil diupdate.",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    self.getDataTable();
                  }
                });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    updatePenawaran(id, text) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin " + text + " penjadwalan?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/update-penawaran",
            data: {
              idjadwal: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diupdate.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    fileLaporan(data, slug) {
      var result = "";
      if (data.jadwal_laporan) {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            item.jadwal_laporan_detail.map((itemDetail) => {
              result += `<a href="${process.env.VUE_APP_BACKEND_URL + itemDetail.jld_file}" target="_blank" class="btn btn-sm btn-info m-1">Download</a>`;
            });
          }
        });
      } else {
        result = "-";
      }
      return result;
    },
    noSurat(data, slug) {
      var result = "-";
      if (data.jadwal_laporan) {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            result = item.jl_nosurat;
          }
        });
      } else {
        result = "-";
      }
      return result;
    },
    rekapDate(data, slug) {
      var tanggal = "-";
      if (!data.jadwal_laporan) {
        return tanggal;
      }
      let weekdaysToAdd;
      let max = 0;
      var date;
      data.jadwal_laporan.map((item) => {
        if (item.jl_slug == slug) {
          date = item.jl_tanggalrealisasi;
          if (slug == "LAPORAN REKAP") {
            max = 5;
          } else if (slug == "LAPORAN LENGKAP") {
            max = 10;
          }
          date = new Date(date);
          weekdaysToAdd = max;

          while (weekdaysToAdd > 0) {
            date.setDate(date.getDate() + 1);
            // Check if the current date falls on a weekend (Saturday or Sunday)
            if (date.getDay() !== 0 && date.getDay() !== 6) {
              weekdaysToAdd--;
            }
          }
          tanggal = date.toISOString().split("T")[0];
          tanggal = this.convertDate(tanggal);
        }
      });
      return tanggal;
    },
    realisasiDate(data, slug) {
      var result = "-";
      if (data.jadwal_laporan) {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            result = item.jl_tanggalrealisasi;
            result = this.convertDate(result);
          }
        });
      } else {
        result = "-";
      }
      return result;
    },
    convertDate(date) {
      const resultDate = new Date(date);
      const day = String(resultDate.getDate()).padStart(2, "0");
      const month = String(resultDate.getMonth() + 1).padStart(2, "0");
      const year = resultDate.getFullYear();
      let tanggal;

      tanggal = `${day}-${month}-${year}`;

      return tanggal;
    },
    last10Years() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear; i >= currentYear - 10; i--) {
        years.push(i);
      }
      this.listYears = years;
    },
    allMonths() {
      const monthsInIndonesia = [];
      const date = new Date();
      for (let month = 0; month < 12; month++) {
        date.setMonth(month);
        const monthName = date.toLocaleDateString("id-ID", { month: "long" });
        monthsInIndonesia.push(monthName);
      }
      this.listMonths = monthsInIndonesia;
    },
    modalRekap(id, slug) {
      this.showModalUpdateTarget = true;
      this.selectedIdJadwal = id;
      this.selectedSlug = slug;
    },
    importFile(event) {
      const input = event.target;
      if (!this.validateFile(input)) {
        return;
      }

      let self = this;
      if ($("#update_file")[0].files[0]) {
        // if ($("#update_file")[0].files[0].size < 2242880) {
        // $("#uploadLoading").html(
        //     '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
        // );

        var FormData = require("form-data");
        var data = new FormData();
        data.append("file", $("#update_file")[0].files[0]);
        var config = {
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/upload",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
          data: data,
        };
        axios(config).then(function (response) {
          self.file_temp = response.data.data.path_file;
        });
      }
    },

    validateFile(input) {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024; // 2MB

      if (file && file.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Size file maksimal 2MB",
          showConfirmButton: true,
          showCancelButton: false,
        });
        input.value = ""; // Reset the file input
        // this.isUploadFile = false;
        return false;
      }
      return true;
    },

    getExport(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/rekrutmen/export-rekrutmen") {
      let self = this;
      self.loadingExport = true;
      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.loadingExport = false;
            var url = process.env.VUE_APP_BACKEND_URL + response_data_fix.filename;
            window.open(url, "_blank");
          } else {
            self.loadingExport = false;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          self.loadingExport = false;
          console.log(errors);
        });
    },
    getSubKegiatan() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        params: {
          tipe: "PEG POG",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_sub_kegiatan = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onChangeSubKegiatan(data) {
      this.tableData.id_sub_kegiatan = data.id_subkegiatan;
      this.getDataTable();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Semua Rekrutmen</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  <router-link :to="{ name: 'add-jadwal-rekrutmen' }">
                    <div class="text-end">
                      <a class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Tambah Penjadwalan Rekrutmen</a>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="tahun">Tahun</label>
                    <select class="form-control" v-model="tableData.selectedYear">
                      <option value="0">Semua Tahun</option>
                      <option v-for="(year, key) in this.listYears" :key="key" :value="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="bulan">Bulan</label>
                    <select class="form-control" v-model="tableData.selectedMonth">
                      <option value="0">Semua Bulan</option>
                      <option v-for="(month, key) in this.listMonths" :key="key" :value="key + 1">
                        {{ month }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="status">Status</label>
                    <select class="form-control" v-model="tableData.selectedStatus">
                      <option value="0">Semua Status</option>
                      <option v-for="(status, key) in this.listStatus" :key="key" :value="status">
                        {{ status.charAt(0).toUpperCase() + status.slice(1) }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="doc">Tipe Dokumen</label>
                    <select class="form-control" v-model="tableData.selectedDocument">
                      <option value="0">Semua Tipe</option>
                      <option v-for="(doc, key) in this.listDocument" :key="key" :value="doc">
                        {{ doc }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="Batch">Sub Kegiatan</label>
                    <v-select
                      :options="option_sub_kegiatan"
                      label="nama_subkegiatan"
                      v-model="selected_sub_kegiatan"
                      @update:modelValue="onChangeSubKegiatan($event)"
                      placeholder="-Pilih Sub Kegiatan-"
                      class="text-secondary"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label style="opacity: 0">Tipe</label><br />
                    <button class="btn btn-info me-2" v-on:click="getDataTable()">Filter</button>
                    <button
                      v-if="(tableData.selectedYear != 0 || tableData.selectedMonth != 0 || tableData.selectedStatus != 0 || tableData.selectedDocument != 0) && loadingExport == false"
                      class="btn btn-success"
                      v-on:click="getExport()"
                    >
                      <i class="fas fa-file-excel"></i> Export
                    </button>
                    <button v-else-if="loadingExport == true" class="btn btn-success disabled"><i class="fas fa-file-excel"></i> Processing...</button>
                    <button v-else class="btn btn-success disabled"><i class="fas fa-file-excel"></i> Export</button>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-bordered table-condensed table-hover">
                  <thead class="bg-dark text-center text-white">
                    <tr>
                      <th class="text-center" rowspan="2">No</th>
                      <th class="text-center" rowspan="2">ID Jadwal</th>
                      <th class="text-center" rowspan="2">Aksi</th>
                      <th class="text-center" rowspan="2">Nama Grup</th>
                      <th class="text-center" rowspan="2">Tanggal Mulai</th>
                      <th class="text-center" rowspan="2">Tanggal Selesai</th>
                      <th class="text-center" rowspan="2">Kegiatan</th>
                      <th class="text-center" rowspan="2">Sub Kegiatan</th>
                      <th class="text-center" rowspan="2">Peserta Diundang</th>
                      <th class="text-center" rowspan="2">Peserta Hadir</th>
                      <th class="text-center" rowspan="2">Keterangan</th>
                      <th class="text-center" rowspan="2">Lokasi</th>
                      <th class="text-center" rowspan="2">PTL</th>
                      <th class="text-center" rowspan="2">QC PTL</th>
                      <th class="text-center" rowspan="2">Petugas</th>
                      <th class="text-center" rowspan="2">Status</th>
                      <th class="text-center" rowspan="2">S. Undangan</th>
                      <th class="text-center" rowspan="2">S. Penugasan EE</th>
                      <th class="text-center" rowspan="2">Berita Acara</th>
                      <th class="text-center" colspan="4">Laporan Rekap</th>
                      <th class="text-center" colspan="4">Laporan Lengkap</th>
                    </tr>
                    <tr>
                      <th class="text-center">Target</th>
                      <th class="text-center">Realisasi</th>
                      <th class="text-center">No.Surat</th>
                      <th class="text-center">File</th>
                      <th class="text-center">Target</th>
                      <th class="text-center">Realisasi</th>
                      <th class="text-center">No.Surat</th>
                      <th class="text-center">File</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                        <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                      </td>
                      <td>#{{ row_data.idjadwal }}</td>
                      <td class="text-center">
                        <div class="btn-group">
                          <router-link
                            :to="{
                              name: 'detail-rekrutmen',
                              params: {
                                id: encryptedUrl(row_data.idjadwal.toString()),
                              },
                            }"
                            class="btn btn-success btn-sm"
                            ><i class="fa fa-eye"></i> Detail
                          </router-link>
                          <button type="button" class="btn btn-danger btn-sm" v-if="row_data.j_is_verifikasi == 1" v-on:click="updatePenawaran(row_data.idjadwal, 'Unpost')">Unpost</button>
                        </div>
                      </td>
                      <td>
                        {{ row_data.jadwal_grup?.nama_grub ? row_data.jadwal_grup.nama_grub : "-" }}
                      </td>
                      <td>
                        {{ row_data.j_tanggal_mulai }}
                      </td>
                      <td>
                        {{ row_data.j_tanggal_sampai }}
                      </td>
                      <td>
                        {{ row_data.j_txt_kegiatan }}
                      </td>
                      <td>
                        {{ row_data.m_nama_kegiatan }}
                      </td>
                      <td>
                        {{ row_data.jadwal_unit_sum_ju_peserta_diundang }}
                      </td>
                      <td>{{ row_data.jadwal_asesi_p_sum_ja_kehadiran ? row_data.jadwal_asesi_p_sum_ja_kehadiran : "0" }}</td>
                      <td>
                        {{ row_data.j_keterangan }}
                      </td>
                      <td>
                        {{ row_data.j_txt_lokasi }}
                      </td>
                      <td>
                        {{ row_data.j_txt_ptl }}
                      </td>
                      <td>
                        {{ row_data.j_txt_ptl }}
                      </td>
                      <td>{{ row_data.nama_petugas }}</td>
                      <td>
                        <span style="white-space: nowrap" class="badge bg-danger" v-if="row_data.j_status == 'void'"> Void </span>
                        <span style="white-space: nowrap" class="badge bg-info" v-if="row_data.j_status == 'Draft'"> Draft </span>
                        <span style="white-space: nowrap" class="badge bg-warning" v-else-if="row_data.j_status == 'verifikasi'"> Verifikasi Jadwal </span>
                        <span style="white-space: nowrap" class="badge bg-success" v-else-if="row_data.j_status == 'proses_ptl'"> Proses Jadwal ke PTL </span>
                        <span style="white-space: nowrap" class="badge bg-primary" v-else-if="row_data.j_status == 'selesai'"> Selesai </span>
                        <span style="white-space: nowrap" class="badge bg-info" v-else-if="row_data.j_status == 'MK Fix'"> MK Fix </span>
                        <span style="white-space: nowrap" class="badge bg-danger" v-else-if="row_data.j_status == 'batal'"> Batal </span>
                        <span v-else>
                          {{ row_data.j_status }}
                        </span>
                      </td>
                      <td class="text-center" v-html="fileLaporan(row_data, 'SURAT UNDANGAN')"></td>
                      <td class="text-center" v-html="fileLaporan(row_data, 'SURAT PENUGASAN REKRUTMEN')"></td>
                      <td class="text-center" v-html="fileLaporan(row_data, 'BERITA ACARA')"></td>
                      <td class="text-center">
                        <button class="btn btn-success btn-sm" @click="modalRekap(row_data.idjadwal, 'LAPORAN REKAP')">Edit</button>
                        {{ rekapDate(row_data, "LAPORAN REKAP") }}
                      </td>
                      <td class="text-center" v-html="realisasiDate(row_data, 'LAPORAN REKAP')"></td>
                      <td class="text-center" v-html="noSurat(row_data, 'LAPORAN REKAP')"></td>
                      <td class="text-center" v-html="fileLaporan(row_data, 'LAPORAN REKAP')"></td>
                      <td class="text-center" v-html="rekapDate(row_data, 'LAPORAN LENGKAP')"></td>
                      <td class="text-center">
                        <button class="btn btn-success btn-sm" @click="modalRekap(row_data.idjadwal, 'LAPORAN LENGKAP')">Edit</button>
                        {{ realisasiDate(row_data, "LAPORAN LENGKAP") }}
                      </td>
                      <td class="text-center" v-html="noSurat(row_data, 'LAPORAN LENGKAP')"></td>
                      <td class="text-center" v-html="fileLaporan(row_data, 'LAPORAN LENGKAP')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>

  <b-modal v-model="showModalUpdateTarget" id="modal-lg" size="lg" title="Update Target" title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <div class="form-group">
            <label for="file">File <small>*kosongi jika tidak ingin merubah</small></label>
            <input type="file" class="form-control" id="update_file" @change="importFile" />
          </div>
          <div class="form-group">
            <label for="tanggalRealisasi">Tanggal Realisasi</label>
            <input type="date" class="form-control" v-model="update_tanggal_realisasi" />
          </div>
          <div class="form-group">
            <label for="noSurat">No Surat</label>
            <input type="text" class="form-control" v-model="update_no_surat" />
          </div>
          <div class="form-group">
            <label for="keterangan">Keterangan</label>
            <textarea class="form-control" v-model="update_keterangan"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="updateRekap(this.selectedIdJadwal, this.selectedSlug)"><i class="fa fa-save"></i> Simpan</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
