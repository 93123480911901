<script>
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";

export default {
  components: {},
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    return {
      showModalSurat: false,
      showModalLaporan: false,

      selected_slug: "",

      path_file_import: "",
      keterangan: "",
      no_surat: "",
      tanggal_realisasi: "",

      data_undangan: [],
      data_penugasan: [],
      data_berita_acara: [],
      data_lengkap: [],
      data_rekap: [],
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.getData();
  },
  methods: {
    getFile(data) {
      var url = process.env.VUE_APP_BACKEND_URL + data;
      window.open(url, "_blank");
    },
    modalAddSurat(tipe) {
      this.showModalSurat = !this.showModalSurat;
      this.selected_slug = tipe;
    },
    modalLaporan(tipe) {
      this.showModalLaporan = !this.showModalLaporan;
      this.selected_slug = tipe;
    },
    inputFile(event) {
      const input = event.target;
      if (!this.validateFile(input)) {
        return;
      }

      let self = this;
      if ($(".formImport")[0].files[0]) {
        if ($(".formImport")[0].files[0].size < 2242880) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $(".formImport")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.path_file_import = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    
    validateFile(input) {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024; // 2MB

      if (file && file.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Size file maksimal 2MB",
          showConfirmButton: true,
          showCancelButton: false,
        });
        input.value = ""; // Reset the file input
        $("#uploadLoading").empty();
        return false;
      }
      return true;
    },

    getData(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/penjadwalan/detail-center/get-laporan"
    ) {
      let self = this;
      var config = {
        method: "get",
        url: url,
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            console.log();
            var all_laporan = response_data_fix.list_data;
            all_laporan.forEach((element) => {
              if (element.jl_slug == "SURAT UNDANGAN") {
                self.data_undangan.push(element);
              } else if (element.jl_slug == "SURAT PENUGASAN EE") {
                self.data_penugasan.push(element);
              } else if (element.jl_slug == "BERITA ACARA") {
                self.data_berita_acara.push(element);
              } else if (element.jl_slug == "LAPORAN REKAP") {
                self.data_rekap.push(element);
              } else if (element.jl_slug == "LAPORAN LENGKAP") {
                self.data_lengkap.push(element);
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    submitDokumen() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/asesmen-center/store-dokumen",
        data: {
          file: self.path_file_import,
          jadwal_idjadwal: self.data_jadwal.idjadwal,
          jl_slug: self.selected_slug,
          jl_created_by: localStorage.getItem("session_user_id"),
          jl_keterangan: self.keterangan,
          jl_nosurat: self.no_surat,
          jl_tanggalrealisasi: self.tanggal_realisasi,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan " + self.selected_slug,
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/delete-dokumen",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Surat Undangan</span>
            <a class="btn btn-sm btn-success ms-2" @click="modalAddSurat('SURAT UNDANGAN')">
              <span><i class="fa fa-plus"></i> Tambah Surat Undangan</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive">
            <table class="table table-bordered mb-0 mt-2">
              <thead class="bg-dark text-white" style="padding: 10px">
                <th style="padding: 5px">No</th>
                <th style="padding: 5px">Keterangan</th>
                <th style="padding: 5px">File</th>
              </thead>
              <tbody>
                <tr v-if="data_undangan.length == 0">
                  <td colspan="3">Data tidak ditemukan</td>
                </tr>
                <tr v-else v-for="(data, key) in data_undangan" :key="key">
                  <td style="width: 10px">{{ key + 1 }}</td>
                  <td>{{ data.jl_keterangan ?? "-" }}</td>
                  <td>
                    <div class="d-flex flex-column" v-for="(detail_file, key) in data.jadwal_laporan_detail" :key="key">
                      <div class="col-12 p-1">
                        <div class="btn-group">
                          <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                            <span><i class="fa fa-eye"></i> Lihat File</span></a>
                          <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                            <i class="fas fa-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Surat Penugasan EE</span>
            <a class="btn btn-sm btn-success ms-2" @click="modalAddSurat('SURAT PENUGASAN EE')">
              <span><i class="fa fa-plus"></i> Tambah Surat Penugasan EE</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive">
            <table class="table table-bordered mb-0 mt-2">
              <thead class="bg-dark text-white" style="padding: 10px">
                <th style="padding: 5px">No</th>
                <th style="padding: 5px">Keterangan</th>
                <th style="padding: 5px">File</th>
              </thead>
              <tbody>
                <tr v-if="data_penugasan.length < 1">
                  <td colspan="3">Data tidak ditemukan</td>
                </tr>
                <tr v-else v-for="(data, key) in data_penugasan" :key="key">
                  <td style="width: 10px">{{ key + 1 }}</td>
                  <td>{{ data.jl_keterangan ?? "-" }}</td>
                  <td>
                    <div class="d-flex flex-column" v-for="(detail_file, key) in data.jadwal_laporan_detail" :key="key">
                      <div class="col-12 p-1">
                        <div class="btn-group">
                          <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                            <span><i class="fa fa-eye"></i> Lihat File</span></a>
                          <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                            <i class="fas fa-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Berita Acara</span>
            <a class="btn btn-sm btn-success ms-2" @click="modalAddSurat('BERITA ACARA')">
              <span><i class="fa fa-plus"></i> Tambah Berita Acara</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive">
            <table class="table table-bordered mb-0 mt-2">
              <thead class="bg-dark text-white" style="padding: 10px">
                <th style="padding: 5px">No</th>
                <th style="padding: 5px">Keterangan</th>
                <th style="padding: 5px">File</th>
              </thead>
              <tbody>
                <tr v-if="data_berita_acara.length < 1">
                  <td colspan="3">Data tidak ditemukan</td>
                </tr>
                <tr v-for="(data, key) in data_berita_acara" :key="key">
                  <td style="width: 10px">{{ key + 1 }}</td>
                  <td>{{ data.jl_keterangan ?? "-" }}</td>
                  <td>
                    <div class="d-flex flex-column" v-for="(detail_file, key) in data.jadwal_laporan_detail " :key="key">
                      <div class="col-12 p-1">
                        <div class="btn-group">
                          <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                            <span><i class="fa fa-eye"></i> Lihat File</span></a>
                          <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                            <i class="fas fa-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-5" />
    <br />
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Laporan Lengkap</span>
            <a class="btn btn-sm btn-success ms-2" @click="modalAddSurat('LAPORAN LENGKAP')">
              <span><i class="fa fa-plus"></i> Tambah Laporan Lengkap</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive">
            <table class="table table-bordered mb-0 mt-2">
              <thead class="bg-dark text-white" style="padding: 10px">

                <th style="padding: 5px">Realisasi</th>
                <th style="padding: 5px">No Surat</th>
                <th style="padding: 5px">File</th>
              </thead>
              <tbody>
                <tr v-if="data_lengkap.length < 1">
                  <td colspan="3">Data tidak ditemukan</td>
                </tr>
                <tr v-else v-for="(data, key) in data_lengkap" :key="key">
                  <td>{{ data.jl_tanggalrealisasi ?? "-" }}</td>
                  <td>{{ data.jl_nosurat ?? "-" }}</td>
                  <td>
                    <div class="d-flex flex-column" v-for="(detail_file, key) in data.jadwal_laporan_detail " :key="key">
                      <div class="col-12 p-1">
                        <div class="btn-group">
                          <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                            <span><i class="fa fa-eye"></i> Lihat File</span></a>
                          <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                            <i class="fas fa-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Laporan Rekap</span>
            <a class="btn btn-sm btn-success ms-2" @click="modalAddSurat('LAPORAN REKAP')">
              <span><i class="fa fa-plus"></i> Tambah Laporan Rekap</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive">
            <table class="table table-bordered mb-0 mt-2">
              <thead class="bg-dark text-white" style="padding: 10px">
                <th style="padding: 5px">Realisasi</th>
                <th style="padding: 5px">No Surat</th>
                <th style="padding: 5px">File</th>
              </thead>
              <tbody>
                <tr v-if="data_rekap.length < 1">
                  <td colspan="3">Data tidak ditemukan</td>
                </tr>
                <tr v-else v-for="(data, key) in data_rekap" :key="key">
                  <td>{{ data.jl_tanggalrealisasi ?? "-" }}</td>
                  <td>{{ data.jl_nosurat ?? "-" }}</td>
                  <td>
                    <div class="d-flex flex-column" v-for="(detail_file, key) in data.jadwal_laporan_detail " :key="key">
                      <div class="col-12 p-1">
                        <div class="btn-group">
                          <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                            <span><i class="fa fa-eye"></i> Lihat File</span></a>
                          <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                            <i class="fas fa-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-modal v-model="showModalSurat" id="modal-lg" size="lg" :title="'Form Tambah ' + selected_slug" title-class="font-18"
    hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Pilih File</label>
          <input class="form-control formImport" type="file" id="formImport" v-on:change="inputFile" />
          <div class="respond-input-file float-left" id="uploadLoading"></div>
          <small class="float-right" style="color: red">*) Max File 2 MB</small>
        </div>
      </div>
    </div>
    <div class="row mt-2" v-if="selected_slug == 'LAPORAN LENGKAP' || selected_slug == 'LAPORAN REKAP'
      ">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Tanggal Realisasi</label>
          <input type="date" class="form-control" v-model="tanggal_realisasi" />
        </div>
      </div>
    </div>
    <div class="row mt-2" v-if="selected_slug == 'LAPORAN LENGKAP' || selected_slug == 'LAPORAN REKAP'
      ">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">No Surat</label>
          <input type="text" class="form-control" v-model="no_surat" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Keterangan</label>
          <textarea class="form-control" type="text" v-model="keterangan" />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="submitDokumen()">
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
